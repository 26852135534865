<template>
    <div>
        <navbar :header="'CAO Form'"></navbar>
        <el-row>
            <el-col :span="isMobile() ? 16: 24" style="padding: 0 16px">
                <div style="z-index: 9999; display: flex; flex-direction: row-reverse;"
                     :style="!isMobile() ? {right: 'calc(1vw + 10px)'}:{}">
                    <el-radio-group style="background: transparent;" v-model="$store.state.caoLevelActiveName"
                                    class="selectLevel">
                        <el-radio-button :title="'8'" size="mini" :label="'8'" border>
                            Level 8
                        </el-radio-button>
                        <el-radio-button :title="'6_7'" size="mini" :label="'6_7'" border>
                            Level 6/7
                        </el-radio-button>
                    </el-radio-group>
                </div>
            </el-col>
        </el-row>
        <div :style="isMobile()?{height: '75vh'}:{height: '85vh'}" style="overflow-y: auto; overflow-x: hidden;">
            <el-row :style="!isMobile() ? {height: '55vh'}: {}" style="padding: 16px;">
                <el-col :xl="6" :lg="6" :md="6" :sm="24" :style="!isMobile() ? {height: '100%'}: {}" :xs="24"
                        style="background-color: white; padding: 16px;"
                        v-if="isIDNameUndefined">
                    <el-row>
                        <div class="tryFree">
                            Fill out Mock CAO
                        </div>
                    </el-row>
                    <el-row v-if="!isMobile()">
                        <el-col>
                            <img :src="`${publicPath}img/yooni_fit/career_fit.svg`" alt="no image"
                                 style="height: 22vh;">
                        </el-col>
                    </el-row>
                    <el-row>
                        <div class="tryFreeDesc">
                            It is so important to start narrowing down your top 10 courses before filling in your real
                            CAO
                            form. Doing this now will make the real thing far less daunting.
                            Remember, all 10 options are important so don’t just put in your top two or three. It’s good
                            to
                            have a plan B, C, D, E etc.
                        </div>
                    </el-row>
                </el-col>
                <el-col :xl="getSpanNumber()"
                        :lg="getSpanNumber()"
                        :md="getSpanNumber()"
                        :sm="24"
                        :xs="24"
                        :style="!isMobile() ? {height: '100%'}: {}"
                        style="background-color: white; padding: 16px; overflow-y: auto;">
                    <el-row v-if="isMobile()"
                            style="display: flex; flex-direction: row-reverse; padding: 0 10px; border: 1px solid #EDEEF5;">
                        <el-button
                                style="padding: 0;"
                                v-if="!getDisableCanDrag" type="text" @click="disableCanDrag=true">
                            Edit
                        </el-button>
                        <el-button v-else type="text" @click="disableCanDrag=false">Save</el-button>
                    </el-row>
                    <table class="caoTable table table-striped"
                           :style="isMobile() ? {'overflow-x': 'auto', width: 'auto', 'white-space': 'nowrap', 'display': 'block'}: {}">
                        <thead class="thead-dark">
                        <tr style="text-align: center;">
                            <th></th>
                            <th>#</th>
                            <th>Course Code</th>
                            <th>Course Title</th>
                            <th v-if="!isMobile()">College</th>
                            <th v-else-if="isIDNameUndefined"></th>
                            <th v-if="!getIsCounsellor"></th>
                        </tr>
                        </thead>
                        <draggable tag="tbody" v-model="caoFormCourses[$store.state.caoLevelActiveName]"
                                   :disabled="!getDisableCanDrag" @end="updateList">
                            <tr :key="item.name"
                                :style="getCode === '' ? {'height': '4vh'}:{}"
                                v-for="(item, index) in getCAOFormCoursesComputed">
                                <td><img :src="`${publicPath}img/dragIcon.svg`" style="cursor:pointer;"
                                         v-if="item.code !== '' && isMobile() && disableCanDrag">
                                </td>
                                <td><span v-if="item.code !== ''">{{ index + 1 }}</span></td>
                                <td>{{ item.code }}</td>
                                <td>{{ item.course_title }}</td>
                                <td v-if="!isMobile()">{{ item.course_college }}</td>
                                <td v-if="isIDNameUndefined && !getIsCounsellor">
                                    <el-button
                                            :style="!isMobile() ? {padding: '0.5vh 0.5vw !important'}: {fontSize: '14px', padding: '1vw !important'}"
                                            @click="removeFromCAOForm(item)"
                                            plain
                                            type="text"
                                            v-if="item.code !== ''">
                                        Remove
                                    </el-button>
                                </td>
                            </tr>
                        </draggable>
                        <tr v-if="getCAOFormCoursesComputed.length === 0">
                            <td colspan="5"
                                style="text-align: justify !important; padding: 2vh 2vw; color: rgba(0, 0, 0, 0.7);">
                                Add courses to your CAO from your Course Shortlist below
                            </td>
                        </tr>
                    </table>
                </el-col>
            </el-row>
            <el-row style="padding: 0 16px;">
                <div class="tryFree" style="color: white;">
                    Course Shortlist
                </div>
                <div style="text-align: justify !important; color: white;padding: 2vh 0;"
                     v-if="getShortlistedCourseComputed.length === 0 && isIDNameUndefined">
                    Shortlist Courses by starring them in the
                    <span style="color: #812828; cursor: pointer;"
                          @click="$router.push('/course/search/')">
                    Course Search
                </span>
                    section
                </div>
                <div style="text-align: justify !important; color: white;"
                     v-else-if="getShortlistedCourseComputed.length === 0 && (studentName !== undefined || studentId !== undefined)">
                    No Shortlisted courses
                </div>
            </el-row>
            <el-row style="margin: 1vh 0; padding: 0 16px;"
                    v-if="getShortlistedCourseComputed.length > 0">
                <el-col :key="index" :lg="8" :md="8" :sm="24" :xl="8" :xs="24"
                        :style="!isMobile() ? {padding: '0 1vh'}: {}"
                        style="margin-top: 1vh; margin-bottom: 1vh;"
                        v-for="(element, index) in getShortlistedCourseComputed">
                    <el-card :style="!isMobile() ? {height: '11vh'}: {height: '12vh'}">
                        <el-row>
                            <el-col :span="6">
                                <CollegeImage :collegeName="element.course_college" :publicPath="publicPath"
                                  :imgStyle="{ width: '100%', height: '10vh', padding: '1vh 0.1vw' }"></CollegeImage>
                            </el-col>
                            <el-col :span="18">
                                <el-row v-if="isIDNameUndefined">
                                    <el-col :offset="22" :span="1"
                                            @click.native="shortlistCourse(element, 'remove')"
                                            style="position:absolute; padding: 0.7vh 0; cursor: pointer;">
                                        x
                                    </el-col>
                                </el-row>
                                <el-row style="height: 100%; margin: 0 0.3vw; display: flex; align-items: center;">
                                    <el-col :span="16">
                                        <el-row>
                                            <div :title="element.course_title" class="shortlistCourseTitle">
                                                <a style="color: #131336;"
                                                   @click="$router.push('/course/' + element.id + '/')">
                                                    {{element.course_title}} <img
                                                        :src="`${publicPath}img/tab_arrow.svg`"
                                                        alt="no image">
                                                </a>
                                            </div>
                                        </el-row>
                                        <el-row>
                                            <el-col>
                                                <div class="shortlistCourseCollege">
                                                    {{element.course_college}}
                                                </div>
                                            </el-col>
                                        </el-row>
                                    </el-col>
                                    <el-col :span="8" style="margin-top: 1vh;">
                                        <button @click="addToCAOForm(element)"
                                                class="unlock-button unlock-button--text--1"
                                                v-if="isIDNameUndefined && courseInCAOForm(element.code) && (getCAOFormCoursesComputed.length < 10 || getCode === '')">
                                            Add to CAO
                                        </button>
                                        <el-button class="unlock-button unlock-button--text--1"
                                                   icon="el-icon-check" plain type="success"
                                                   v-else-if="!courseInCAOForm(element.code)">
                                            Added
                                        </el-button>

                                    </el-col>
                                </el-row>
                            </el-col>
                        </el-row>
                    </el-card>
                </el-col>
            </el-row>
        </div>

    </div>
</template>

<script>
  import draggable from 'vuedraggable';
  import {mapActions, mapGetters} from 'vuex';
  import Navbar from '../../main_components/Navbar';
  import CollegeImage from '../../page_components/CommonComponents/CollegeImage.vue';

  export default {
    name: 'CAOForm',
    components: {Navbar, draggable, CollegeImage},
    ...mapActions(['setShortlistedCourses']),
    props: ['studentId', 'studentName'],
    mounted() {
      let self = this;
      self.sendAnalytics();
      self.$store.state.loadingScreen = true;
      let id = 0;
      if (self.studentId === undefined || self.studentName === undefined) {
        id = 0;
      } else {
        id = self.studentId;
        self.$store.state.counsellorStudentId = self.studentId;
        self.$store.state.counsellorStudentName = self.studentName;
      }
      self.checkUserLoggedIn().then(response => {
        // self.caoFormCourses = self.$store.state.caoFormCourses;
        self.caoFormCourses = {'8': [], '6': []};
        self.getShortlistedCourse(id);
        self.getCAOForm(id).then(response1 => {

        });

      }).catch(error => {
        error;
        self.$store.state.loadingScreen = false;
      });
    },
    methods: {
      ...mapActions(['setCAOFormCourses']),
      getSpanNumber: function() {
        if (this.studentId === undefined && this.studentName === undefined)
          return {span: 17, offset: 1};
        return {span: 24, offset: 0};
      },
      updateList: function() {
        let self = this;
        let url = self.getBaseUrl() + '/keywords/cao_form/update/';
        // self.$store.state.loadingScreen = true;
        $.ajax({
          beforeSend: function(request) {
            request.setRequestHeader('Authorization',
                'Token ' + self.getAuthToken());
          },
          url: url,
          contentType: 'application/json',
          data: JSON.stringify({courses: self.caoFormCourses, level: self.$store.state.caoLevelActiveName}),
          type: 'POST',
          success: function(response) {
            self.caoFormCourses = response['cao_form_courses'];
            self.setCAOFormCourses(response['cao_form_courses']);
            self.$store.state.loadingScreen = false;
          },
          error: function(error) {
            error;
            self.$store.state.loadingScreen = false;
          },
        });
      },
      courseInCAOForm: function(courseCode) {
        let caoCourses = this.$store.state.caoFormCourses[this.$store.state.caoLevelActiveName];
        if (caoCourses !== '' && caoCourses !== undefined) {
          let filteredData = caoCourses.filter(function(element) {
            return element.code === courseCode;
          });
          return filteredData.length === 0;
        }
        return true;
      },
      getCAOForm: function(studentId = 0) {
        let self = this;
        return new Promise(function(resolve, reject) {
          self.$store.state.loadingScreen = true;
          let url = self.getBaseUrl() + '/keywords/cao_form/list/' + studentId + '/';
          $.ajax({
            beforeSend: function(request) {
              request.setRequestHeader('Authorization',
                  'Token ' + self.getAuthToken());
            },
            url: url,
            type: 'GET',
            success: function(response) {
              self.caoFormCourses = response['cao_form_courses'];
              self.setCAOFormCourses(self.caoFormCourses);
              if (!self.isMobile() && self.studentId === undefined && self.studentName === undefined)
                self.disableCanDrag = true;
              self.$store.state.loadingScreen = false;
              resolve(true);
            },
            error: function(error) {
              error;
              self.$store.state.loadingScreen = false;
              reject(false);
            },
          });
        });
      },
      addToCAOForm: function(course) {
        let self = this;
        // self.$store.state.loadingScreen = true;
        let url = self.getBaseUrl() + '/keywords/cao_form/add/';
        $.ajax({
          beforeSend: function(request) {
            request.setRequestHeader('Authorization',
                'Token ' + self.getAuthToken());
          },
          url: url,
          data: {courses: course['code'], level: self.$store.state.caoLevelActiveName},
          type: 'POST',
          success: function(response) {
            self.sendAnalyticsEvents('cao_courses', course.code);
            self.caoFormCourses = response['cao_form_courses'];
            self.setCAOFormCourses(response['cao_form_courses']);
            self.$store.state.loadingScreen = false;
          },
          error: function(error) {
            error;
            self.$store.state.loadingScreen = false;
          },
        });
      },
      removeFromCAOForm: function(course) {
        let self = this;
        // self.$store.state.loadingScreen = true;
        let url = self.getBaseUrl() + '/keywords/cao_form/remove/';
        $.ajax({
          beforeSend: function(request) {
            request.setRequestHeader('Authorization',
                'Token ' + self.getAuthToken());
          },
          url: url,
          data: {courses: course['code'], level: self.$store.state.caoLevelActiveName},
          type: 'POST',
          success: function(response) {
            self.caoFormCourses = response['cao_form_courses'];
            // self.caoFormCourses.push({'code': '', 'title': '', 'college': ''});
            self.setCAOFormCourses(response['cao_form_courses']);
            self.$store.state.loadingScreen = false;
          },
          error: function(error) {
            error;
            self.$store.state.loadingScreen = false;
          },
        });
      },
    },
    ...mapGetters(['getShortlistedCourses', 'getCAOFormCourses']),
    computed: {
      isIDNameUndefined: function() {
        return this.studentName === undefined && this.studentId === undefined;
      },
      getDisableCanDrag: function() {
        return this.disableCanDrag;
      },
      getShortlistedCourseComputed: function() {
        return this.getShortlistedCourses()[this.$store.state.caoLevelActiveName];
      },
      getCAOFormCoursesComputed: function() {
        if (this.caoFormCourses[this.$store.state.caoLevelActiveName] !== undefined)
          return this.caoFormCourses[this.$store.state.caoLevelActiveName];
        return [];
      },
      getCode: function() {
        if (this.caoFormCourses[this.$store.state.caoLevelActiveName].length > 0)
          return this.caoFormCourses[this.$store.state.caoLevelActiveName][0].code;
      },
    },
    data: function() {
      return {
        loadingScreen: false,
        caoFormCourses: [],
        disableCanDrag: false,
      };
    },
  };
</script>

<style scoped>
    /*#caoSubjects >>> .el-card__body > .el-row {*/
    /*    padding: 20px !important;*/
    /*}*/

    .box {
        height: 7vh;
        font-weight: 500 !important;
        font-size: 12px;
        color: #000000;
        text-align: center;
        padding: 0 3px;
        display: flex;
    }

    .box .el-row {
        margin: auto;
    }

    .thead-dark > tr > th {
        font-weight: 600;
    }

    .caoTable {
        border: 1px solid #EDEEF5;
    }
</style>
