<template>
    <div class="high-nav-control" id="topnav">
        <!--the below el row is for website-->
        <el-row style="display: flex; align-items: center; background-color: #17484C; ">
            <el-col :span="22" v-if="!isMobile()">
                <div class="component-title" v-html="header"></div>
            </el-col>
            <el-col :span="22" v-else>
                &nbsp;
            </el-col>
            <el-col :span="2" v-if="!isMobile()">
                <profile-avatar></profile-avatar>
            </el-col>
        </el-row>
    </div>
</template>
<script>
  import ProfileAvatar from './ProfileAvatar';

  export default {
    components: {ProfileAvatar},
    props: ['header'],
    computed: {},
    methods: {},
  };
</script>

<style>
    .high-nav-control {
        /*background: #F5F6FA;*/
    }

    .nav-control {
        padding-top: 1vh;
        align-items: right;
    }

    .col {
        height: auto;
    }

    i {
        line-height: unset !important;
    }

    .menu-title {
        height: 100%;
        font-family: Inter;
        font-style: normal;
        font-size: 10vw !important;
        text-align: center;
        cursor: pointer;
        font-weight: 900;
        color: #000000 !important;
    }

    .menu-head {
        font-size: 20px;
        line-height: unset !important;
        text-align: left !important;
        color: grey;
        font-weight: 400;
    }


</style>
